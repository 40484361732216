export default {
    name: "article",
    props: {
    },
    data() {
        return {
        };
    },
    watch: {
    },
    components: {
    },
    computed: {
    },
    mounted() {
    },
    methods: {

    },
};
